<template>
  <div class="activity" ref="activity">
    <div class="activity-search">
      <img class="search-icon" src="https://cdn.cailu88.com/jingxuanshi/search_icon@2x.png"/>
      <van-field class="search-input" placeholder="搜索商品标题获取优惠信息" @click="handleInput"/>
    </div>
        <div class="activity-banner" v-if="activityInfo.activityImage">
          <img :src="activityInfo.activityImage"/>
        </div>
    <div class="activity-banner" v-else>
      <iframe :src="activityInfo.activityVideo" frameborder='0'
              allow='autoplay;encrypted-media' allowfullscreen
              style='width:100%;'>
      </iframe>
    </div>
    <div class="category-list">
      <template v-for="(item, index) in categoryList">
        <div :key="index" :class="[item.selected ? 'scroll-view-item-active' : '','scroll-view-item']"
             @click="handleChangeCat(item.catId)">
          <p>{{ item.alias }}</p>
          <div v-if="item.selected" class="active-tab"></div>
        </div>
      </template>
    </div>
    <div class="goods-list" :style="{background: goodsList.length === totalCount ? '#ffffff' : '#f9f9f9'}">
      <template v-for="(item, index) in goodsList">
        <div class="goods-wrap" :key="index" @click="goodsDetails(item)">
          <div class="goods-image">
            <img v-lazy="item.pictUrl"/>
          </div>
          <div class="goods-info">
            <div class="goods-title">
              <span
                :class="item.itemSource === 3 ? 'label-pdd' : item.itemSource === 4 ? 'label-wph' : item.itemSource === 5 ? 'label-sn' : item.itemSource === 2 ? 'label-jd' : item.itemSource === 1 && item.userType == 0 ? 'label-tb' : item.itemSource === 1 && item.userType == 1 ? 'label-tm' : ''">{{
                  item.itemSource === 3 ? '拼多多' : item.itemSource === 4 ? '唯品会' : item.itemSource === 5 ? '苏宁' : item.itemSource === 2 ? '京东' : item.itemSource === 1 && item.userType == 0 ? '淘宝' : item.itemSource === 1 && item.userType == 1 ? '天猫' : ''
                }}</span>
              <!-- <image class="jd-icon" azy-load="true" src="https://cdn.cailu88.com/jingxuanshi/jd_icon@2x.png"></image> -->
              <span>{{ item.itemTitle }}</span>
            </div>
            <div class="goods-discounts">
              <div class="goods-coupon" v-if="item.couponValue">
                <span>{{ item.couponValue }}元券</span>
              </div>
              <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
            </div>
            <div class="goods-price-volume">
              <div class="price">¥ {{ item.reservePrice }}</div>
              <div class="volume" v-if="item.volume && !free">
                <span style="color: #333">{{ item.volume }}人</span>
                <span> 已购</span>
              </div>
            </div>
            <div class="goods-promotion-volume">
              <div>
                <span class='money-symbol'>¥ </span>
                <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
              </div>
              <div class="volume" v-if="item.volume && free">
                <span v-if="item.volume < 10000" style="color: #333">{{ item.volume }}人</span>
                <span v-else style="color: #333">{{ parseFloat((item.volume / 10000).toFixed(2)) }}万人</span>
                <span> 已购</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div :class="['loading-wrap', goodsList.length < 5 ? 'loading-wrap-white':'']">
        <img v-if="goodsList.length < totalCount" class="loading"
             src="https://cdn.cailu88.com/jingxuanshi/loading@2x.png"/>
        <p v-if="goodsList.length === totalCount">
          暂无更多
        </p>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Lazyload } from 'vant'
import Loading from '../../components/loading'
import { hybrid } from 'hybrid-api'

const api = require('../../utils/api').api

Vue.use(Field)
Vue.use(Lazyload, {
  lazyComponent: true,
})
export default {
  name: 'activity',
  data () {
    return {
      token: '',
      id: '',               //test
      version: '',
      channel: '',
      free: true,
      click: true,
      catId: '',
      page: 0,
      pageSize: 20,
      totalCount: 0,
      activityInfo: {},
      goodsList: [],
      categoryList: [],           // 类目数组
    }
  },
  created () {
    document.title = ' '
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    this.id = this.$route.query.id
  },
  mounted () {
    window.addEventListener('scroll', this.lazyLoading) // 滚动到底部，再加载的处理事件
    this.handleGetActivityInfo()
    this.handleGetGoodsList()
  },
  destroyed () {
    window.removeEventListener('scroll', this.lazyLoading)
    //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
  },
  components: {
    Loading
  },
  methods: {
    handleGetActivityInfo () {
      this.$axios(api.activityInfo, {
        params: {
          activityId: this.id,
          clientType: 2,
        }
      }).then(res => {
        this.activityInfo = res.data.data
        document.title = this.activityInfo.activityName
        this.categoryList = this.activityInfo.allCategories
        this.categoryList.map(item => {
          if (item.catId === 0) {
            item.selected = true
          } else {
            item.selected = false
          }
        })
      }).catch(err => {
        console.info(err)
      })
    },
    lazyLoading () {
      // 获取 可视区高度`、`滚动高度`、`页面高度`
      let timer = null
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      this.scroll = scrollHeight
      this.height = scrollTop + clientHeight
      if (scrollTop + clientHeight + 10 >= scrollHeight) { // 滚动到底部，逻辑代码
        //事件处理
        if (this.goodsList.length < this.totalCount && this.totalCount !== 0) {
          this.handleGetGoodsList('up')
        }
      }
    },
    handleGetGoodsList (type) {
      if (!this.click) {
        return false
      }
      this.page++
      let that = this
      this.click = false
      this.$axios(api.activityDetails, {
        params: {
          activityId: this.id,
          clientType: 2,
          catId: this.catId,
          page: this.page,
          pageSize: this.pageSize,
        }
      }).then(res => {
        this.goodsList = type == 'up' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
        that.click = true
      }).catch(err => {
        console.info(err)
        that.click = true
      })
    },
    handleInput () {
      let event = 'openSearch'
      let param = {
        itemSource: this.activityInfo.zoneSource
      }
      this.$h5AppLink(event, param)
    },
    handleChangeCat (id) {
      this.page = 0
      this.catId = id
      this.categoryList.map(item => {
        if (item.catId === id) {
          item.selected = true
        } else {
          item.selected = false
        }
      })
      this.handleGetGoodsList()
    },
    // 点击商品
    goodsDetails (item) {
      let event = 'openGoodsInfo'
      let param = {
        'itemId': item.itemId,
        'itemSource': item.itemSource,
        'sellerId': item.sellerId
      }
      this.$h5AppLink(event, param)
    }
  }
}
</script>
<style>
</style>
<style lang="less" scoped>
.activity {
  min-height: 100vh;

  .activity-search {
    padding-top: 10px;
    text-align: center;
    background: #FFEA1C;
    /* background-color: #df3520; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .search-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 24px;
      top: 22px;
      z-index: 3;
    }

    .search-input {
      margin: 0 9px 12px 9px;
      padding: 8px 14px 7px 43px;
      width: 95%;
      background: #ffffff;
      border-radius: 18px;
    }
  }

  .activity-banner {
    padding-top: 60px;

    video,
    img {
      width: 100%;
    }
  }

  .category-list {
    margin-top: 5px;
    padding-left: 10px;
    background-color: #fff;
    width: 95%;
    height: 45px;
    white-space: nowrap;
    overflow-x: auto;

    .scroll-view-item {
      margin-right: 28px;
      color: #333;
      display: inline-block;

      & > p:first-child {
        font-size: 15px;
        line-height: 20px;
      }

      .active-tab {
        width: 20px;
        height: 4px;
        background: #ffea1c;
        border-radius: 2px;
      }
    }

    scroll-view-item:first-child {
      padding-left: 5px;
    }

    .scroll-view-item-active {
      & > p:first-child {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
      }
    }
  }

  .goods-list {
    background: #f9f9f9;
    width: 100%;
    min-height: 532px;
    padding-top: 8px;
    padding-bottom: 8px;

    .goods-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0px 8px 6px 8px;
      padding: 10px;
      max-height: 140px;
      background: #fff;
      border-radius: 8px;

      .goods-image {
        & > img {
          width: 120px;
          height: 120px;
          border-radius: 8px;
        }
      }

      .goods-info {
        margin-left: 10px;

        .goods-title {
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          color: #333333;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

          & > span:first-child {
            font-size: 10px;
            color: #fff;
            margin-right: 7px;
            border-radius: 2px;
          }

          .label-tb {
            font-size: 8px;
            padding: 1px 4px;
            background: #FF8502;
          }

          .label-tm {
            font-size: 8px;
            padding: 1px 4px;
            background: #E44A59;
          }

          .label-jd {
            font-size: 8px;
            padding: 1px 4px;
            background-color: #e44a59;
          }

          .label-pdd {
            font-size: 8px;
            padding: 1px 3px;
            background-color: #ff5d87;
          }

          .label-wph {
            font-size: 8px;
            padding: 1px 3px;
            background-color: #ed008c;
          }

          .label-sn {
            font-size: 8px;
            padding: 1px 3px;
            background-color: #ffaa02;
          }
        }

        .goods-discounts {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 20px;
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          /* color: #8b7f08; */
          line-height: 20px;
          margin-top: 3px;

          .goods-coupon,
          .goods-cashback {
            color: #fff;
            background-color: #E44A59;
            border-radius: 2px;
          }

          .goods-cashback {
            padding: 0 4px;
            color: #FF3B3C;
            background: rgba(255, 59, 60, 0.1);
          }

          .goods-coupon {
            padding: 0 8px 0 8px;
            margin-right: 8px;
            position: relative;
            text-align: center;
            width: 48px;
            height: 20px;
            background: url("https://cdn.cailu88.com/jingxuanshi/client_activity_coupon.png");
            background-size: cover;
            /* overflow: hidden; */
          }

          //.goods-coupon:before {
          //  /* display: inline-block; */
          //  content: '';
          //  width: 5px;
          //  height: 5px;
          //  border-radius: 50%;
          //  background-color: #fff;
          //  position: absolute;
          //  top: 50%;
          //  left: -2px;
          //  transform: translate(0, -50%);
          //}
          //
          //.goods-coupon:after {
          //  /* display: inline-block; */
          //  content: '';
          //  width: 5px;
          //  height: 5px;
          //  border-radius: 50%;
          //  background-color: #fff;
          //  position: absolute;
          //  top: 50%;
          //  right: -2px;
          //  transform: translate(0, -50%);
          //}
        }

        .goods-price-volume {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;

          .price {
            color: #999;
            text-decoration: line-through;
          }
        }

        .goods-promotion-volume {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .money-symbol {
            font-size: 13px;
            font-weight: bold;
            color: #e44a59;
            line-height: 18px;
          }

          .goods-current-price {
            height: 30px;
            font-size: 22px;
            font-weight: bold;
            color: #e44a59;
            line-height: 30px;
          }
        }

        .goods-price-volume .price,
        .goods-promotion-volume .volume,
        .goods-price-volume .volume {
          height: 17px;
          font-size: 12px;
          line-height: 17px;
        }

        .goods-promotion-volume .volume,
        .goods-price-volume .volume {
          color: #999;
        }
      }
    }

    .loading-wrap {
      font-size: 12px;
      line-height: 17px;
      color: #b6b6b6;
      text-align: center;
      padding: 15px 0;

      .loading {
        width: 35px;
        height: 35px;
        animation: loading 1s linear 0s infinite;
      }

      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .loading-wrap-white {
      margin-top: 6px;
      background-color: #fff;
    }
  }
}
</style>
